.TreatmentBox{
    padding-top: 2%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width:80%;
    height: fit-content;
    overflow: hidden;
}


.Gallery_Images{
    display: inline-block;
    position: relative;
    height: fit-content;
    width: 30%;
    max-width: 30%;
    overflow: hidden;
    border-radius: 0.3vw;
    border-bottom-left-radius: 1vw;
    border-top-right-radius: 1vw;
    border: 0.5vw solid #124742;
    filter: drop-shadow(1vw 2.2vw 0.2vw rgba(18,71,66,0.35));
    -webkit-filter: drop-shadow(1vw 2.2vw 0.2vw rgba(18,71,66,0.35));
}


.Gallery_Images img{
  
    width: 100%;
}

.TreatmentBox_Content{
    width: 65%;
    display: flex;
    justify-content:space-evenly;
    flex-direction:column;
    align-items: left;
}

.TreatmentBox_Content_Name{
    font-size: 1.5vw;
    color: #124742;
    font-weight: 700;
    font-family: "Roboto";
}

.TreatmentBox_Content_CRO{
    color: #c7ab68;
    font-family: "Roboto";
    font-size: 1.3vw;
}

.TreatmentBox_Content_Title{
    font-size: 1.3vw;
    color: #124742;
    font-weight: 700;
    font-family: "Roboto";
}

.TreatmentBox_Content_Text{
    width: 70%;
    font-size: 1.2vw;
}