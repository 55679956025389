.Home_Header {
  position: relative;
  width: 100%;
  background: url("../../../assets/Home/Header/gold_pattern.jpg");
  min-width: 100%;
  background-size: cover;
  aspect-ratio: 1/0.2;
}

.FirstLayer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 1/0.749367089;
}

.TextBox {
  margin: 0 auto;
  width: fit-content;
}

.FirstLine,
.SecondLine {
  display: block;

  color: #ffffff;
}

.FirstLine {
  font-weight: 400;
  font-size: 4vw;
  text-align: right;
  font-family: "Philosopher regular";
  height: fit-content;
  margin: 0;
  padding: 0;
}
.lineBox {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.SecondLine {
  padding: 0;
  line-height: 0;
  font-size: 2.5vw;
  text-align: right;
  font-family: "Philosopher";
  height: fit-content;
  width: 100%;
}

.ThirdLine {
  display: block;
  font-family: "CallingHeart";
  font-size: 4vw;
  height: fit-content;
  width: 100%;
  text-align: right;
  color: #c7ab68;
}

.SecondLayer {
  position: absolute;
  top: 0;
  background-color: transparent;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.BoxArea {
  z-index: 100000;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.BoxHeaderMenu {
  cursor: pointer;
  width: 1vw;
  aspect-ratio: 1/1;
  border: 0.2vw solid rgb(199, 171, 104);
}

.Header_LeftBox,
.Header_RightBox {
  vertical-align: top;
  display: inline-block;
  height: 100%;
  max-height: 100%;
}

.Header_LeftBox {
  position: absolute;
  left: 10%;
  aspect-ratio: 1/0.894160584;
}

.Header_RightBox {
  position: absolute;
  right: 18%;
  aspect-ratio: 1/1.12254902;
}

.leftLogo {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.3;
}

.rightLogo {
  position: absolute;
  margin: auto;
  right: 2%;
  z-index: -1;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0.3;
}
.Subtitle {
  font-size: 1.2vw;
}
.Subtitle_First {
  text-align: center;
  font-family: "Roboto";
  color: #ffffff;
}

.Subtitle_Second {
  font-family: "Roboto";
  color: #c7ab68;
}

.fadeIn {
  animation: fadeIn 5s;
}

.fadeOut {
  animation: fadeOut 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  } /* Início da fase de manutenção */
  80% {
    opacity: 1;
  } /* Fim da fase de manutenção */
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  } /* Início da fase de manutenção */
  80% {
    opacity: 0;
  } /* Fim da fase de manutenção */
  100% {
    opacity: 0;
  }
}
