.Home_Blog {
  position: relative;

  width: 100%;
  min-height: fit-content;
  height: auto;

  overflow-y: visible;
  background-color: #466345;
  box-sizing: content-box;
}

.Home_Blog_Content {
  display: flex;
  width: 70%;
  position: absolute;
  height: 100%;
  z-index: 10;
}

/*Imagem PLanta*/
.Home_Blog .Plant_Section {
  display: inline-block;
  z-index: 10;
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 20%;
}

.Plant_Img {
  position: absolute;
  max-width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  max-width: auto;
  height: 120%;
}

/*Logo*/
.Blog_Logo {
  display: inline-block;
  z-index: 10;
  max-width: 20%;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Blog_Logo p {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  font-size: 5vw;
  opacity: 0.078;
  letter-spacing: 1vw;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  font-family: "Philosopher";
}

/*Caixa de noticias*/

.NewBox_Area {
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;

  z-index: 10;
}

.NewBox_Area .Box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 82.5%;
  aspect-ratio: 1/0.5;
  background-color: #ffffff;
  border-bottom-left-radius: 1vw;
  border-top-right-radius: 1vw;
  max-height: 90%;
}

/*Imagem de fundo*/
.Home_Blog .ImageArea {
  margin-left: 50%;
  clear: both;
  display: inline-block;
  position: relative;
  width: 50%;
  height: auto;
}

.Home_Blog .ImageArea img {
  width: 100%;
  height: auto;
}

.LayerDecoration {
  position: absolute;
  top: 0;
  right: 0;

  bottom: 0;
  margin: auto;
  z-index: 5;
  width: 95%;
  height: 85%;
  border-top: 1vw solid #c7ab68;
  border-left: 1vw solid #c7ab68;
  border-bottom: 1vw solid #c7ab68;
}

@media (max-width: 767px), (min-height: 1000px) {
  .Home_Blog {
    height: 50vw !;
  }
}
