.Institute_Box {
  position: relative;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-height: auto;
  padding-bottom: 10vw;
  transition: transform 10s ease-in-out;
  flex-grow: 2;
}

.Institute {
  padding-top: 5%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  width: 100%;
  height: auto;
  font-family: "Philosopher";
  transition: transform 10s ease-in-out;
}

.Institute_Content,
.History_Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  align-self: stretch;
}

.Institute img {
  width: 55%;
  height: auto;
}

.Institute_Content_Header {
  display: flex;
  align-items: center;
  height: 10%;
  width: 90%;
  margin: 0 auto;
  font-size: 2vw;
  color: #c7ab68;
}
.Institute_Content_Text {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  font-size: 1.1vw;
  width: 90%;
  margin: 0 auto;
}
.Carousel {
  width: 55%;
  transition: transform 1s ease-in-out;
}

.carousel_item {
  transition: transform 1s ease-in-out;
}

.History {
  position: relative;
  padding-top: 5%;
  padding-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  font-family: "Philosopher";
  aspect-ratio: 1/0.2;
  overflow: hidden;
}

.History_Gallery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  height: 100%;
}

.Gallery_Images {
  position: relative;
  width: 30%;
  max-width: 30%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.inner {
  display: inline-block;
  position: relative;
  right: -50%;
  height: 100%;
}

.Gallery_Images img {
  position: relative;
  left: -50%;
  height: 100%;
}
@media (max-width: 767px), (min-height: 1000px) {
  .Institute {
    flex-direction: column;
  }
  .Institute_Content {
    width: 90%;
    margin: 0 auto;
  }
  .Institute_Content_Header {
    font-size: 4vw;
  }
  .Institute_Content_Text {
    width: 100%;
    font-size: 3vw;
  }

  .Carousel {
    width: 90%;
  }
}
