.ImageBox{
    width: 20%;
    max-width: 20%;
    overflow: hidden;
    aspect-ratio: 1/0.67;
    background-color: white;
    filter: drop-shadow(1vw 2vw 0.4vw rgba(18,71,66,0.35));
    border: 0.3vw solid #124742;
    border-top-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
}


.ImageBox img{
    width: 100%;
    min-height: 100%;
}


.ImageBox:hover{
    transition: 0.5s ease;
    transform: translateY(-1vw);
}