.Header_Container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  aspect-ratio: 1/0.1;
  font-family: "Roboto";
  background-color: rgb(9, 35, 33, 0.8);
  z-index: 2;
  justify-content: space-evenly;
}
/*Logo Area*/
.LeafBackground {
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  z-index: 0;
  opacity: 0.5;
}
.Header_Container .Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 20%;
  max-width: 20%;
}

/*Fim Logo Area*/

.Logo img {
  max-height: 90%;
  max-width: 90%;
}

/*MenuBar Area*/

.Header_Container .MenuBar {
  position: relative;
  height: 100%;
  width: fit-content;
  white-space: nowrap;
}

.Header_Container .MenuBar ul {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  list-style: none;
  padding-left: 0;
  text-align: center;
}

.Header_Container .MenuBar ul li {
  padding: 0.5vw;
  width: fit-content;
  font-size: 0.8vw;
  font-family: "Philosopher regular";
  display: inline-block;
  cursor: pointer;
}

.Header_Container .MenuBar ul li a {
  text-decoration: none;
  color: #ffffff;
}

/*Fim MenuBar Area*/

/*Links Area*/

.Header_Container .Links {
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 20%;
  max-width: 20%;
}

.Links img {
  max-height: 50%;
  max-width: 40%;
}
/*Fim Links Area*/
@media (max-width: 767px), (min-height: 1000px) {
  .Header_Container {
    aspect-ratio: 1/0.3;
  }
  .Header_Container .MenuBar ul li {
    font-size: 1.8vw;
  }

  .Lupa {
    width: 15%;
  }
}
