html,
body {
  min-width: 300px;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Evita a rolagem horizontal */
}

.App {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100vw;
}
.Wpp_ICon2 {
  z-index: 20;
  width: 4%;
  position: fixed;
  top: 0;
  right: 1%;
  bottom: 0;

  margin: auto;
}

* {
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
  color: inherit;
}
@media (max-width: 767px), (min-height: 1000px) {
  .Wpp_ICon2 {
    width: 10%;
  }
}
