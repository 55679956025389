.PageFooter {
  width: 100%;
  aspect-ratio: 1/0.3;
  background-color: #ffffff;
}

.PageFooter_InfoData {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  aspect-ratio: 1/0.15;
}

.PageFooter_InfoData .Logo_img {
  max-width: 20%;
  max-height: 100%;
}

.PageFooter_InfoData table {
  height: 80%;
  width: 20%;
  font-size: 1.2vw;
}

.PageFooter_InfoData table thead {
  font-family: "Philosopher";
  text-align: left;
  color: #c7ab68;
  text-transform: uppercase;
  font-weight: bold;
}

.IconImgs {
  display: flex;
  align-items: center;
  height: 100%;
  width: 30%;
}

.LicenseICons {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 50%;
  height: 80%;
}

.GoogleIcon {
  width: 60%;
}
.shieldIcon img {
  aspect-ratio: 1/1;
  width: 2vw;
}
.shieldIcon {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  gap: 1vw;
  font-size: 0.7vw;
  text-transform: uppercase;
  color: #009200;
  font-family: "Gotham Rounded";
}

.Wpp_ICon {
  max-width: 20%;
  max-height: 90%;
}

.Wpp_ICon img {
  max-width: 100%;
  max-height: 100%;
}

.CookiesTerms {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/0.1;
  width: 100%;
  background-color: #063427;
}

.CookiesTerms p {
  font-size: 0.7vw;
  font-family: "Roboto";
  max-width: 60%;
  width: fit-content;
  color: #ffffff;
  font-weight: 400;
  font-family: "Roboto";
  text-align: right;
  text-transform: uppercase;
  padding-right: 1%;
}

.AgreeButton {
  width: 10%;
  aspect-ratio: 1/0.3;
  background-color: transparent;
  color: #ffffff;
  border: 0.3vw solid #c7ab68;
  font-family: "Gotham Rounded";
  font-size: 1vw;
  text-align: center;
}
.Statement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2vw;
  aspect-ratio: 1/0.1;
}
@media (max-width: 767px), (min-height: 1000px) {
  .PageFooter {
    aspect-ratio: 1/0.5;
  }

  .PageFooter_InfoData {
    aspect-ratio: 1/0.3;
  }
  .PageFooter_InfoData table {
    font-size: 1.8vw;
  }

  .IconImgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 40%;
  }
  .LicenseICons {
    width: 60%;
  }
  .shieldIcon img {
    width: 3vw;
  }

  .shieldIcon p {
    font-size: 1.8vw;
  }
}
