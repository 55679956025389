.Team_Box {
  position: relative;
  width: 100%;
  flex-grow: 2;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: auto;
}

.Content {
  display: flex;
  flex-direction: column;
  background-color: red;
  gap: 1vw;
}

.Team_Header {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Philosopher";
}

.Team_Header .Team_Header_Title {
  font-size: 2.5vw;
  color: rgb(199, 171, 104);
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.Team_Header .Team_Header_SubTitle {
  font-size: 1.5vw;
  font-family: "Roboto";
  color: rgb(18, 71, 66);
  font-weight: bold;
}

.AppointmentButton {
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/0.1;
  padding-top: 2%;
  padding-bottom: 3%;
}

.AppointmentButton p {
  text-decoration: none;
  display: flex;
  font-size: 1.2vw;
  align-items: center;
  justify-content: center;
  width: 25%;
  aspect-ratio: 1/0.2;
  border-bottom-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border: 0.5vw solid #c7ab68;
  filter: drop-shadow(1vw 2.2vw 0.2vw rgba(199, 171, 104, 0.35));
}

@media (max-width: 767px), (min-height: 1000px) {
  .Team_Header .Team_Header_Title {
    font-size: 5vw;
  }

  .Team_Header .Team_Header_SubTitle {
    font-size: 2.8vw;
  }
  .AppointmentButton {
    width: 100%;
  }
  .AppointmentButton p {
    font-size: 2.2vw;
    text-align: center;
  }
}
