.Connect_Box {
  position: relative;
  margin: 0 auto;
  display: flex;
  height: fit-content;
  width: 80%;
  padding-bottom: 10vw;
  max-height: auto;
}

.ConnectForm {
  padding-top: 1vw;
  width: 40%;
  height: fit-content;
  display: flex;
  gap: 1vw;
  margin: 0;
  flex-direction: column;
}

.ConnectForm input {
  text-align: center;
  border: 0.2vw solid rgb(199, 171, 104);
  width: 95%;
  aspect-ratio: 1/0.1;
  font-size: 1vw;
}

.ConnectForm button {
  font-size: 1.2vw;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  background-color: rgb(199, 171, 104);
  border: 0.2vw solid rgb(199, 171, 104);
  aspect-ratio: 1/0.1;
  width: 95%;
}

.ConnectIcons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
}

.ConnectIcons a,
.ConnectIcons img {
  display: inline-block; /* Ajustado para inline-block */
  width: 10%;
  min-width: 10%;
  margin: 0;
  padding: 0;
  text-decoration: none; /* Remove o sublinhado dos links */
}

.ConnectIcons a img {
  display: block;
  object-fit: contain;
  width: 100%; /* Ajustado para 100% para ocupar toda a largura do link */
  margin: 0;
  padding: 0;
  min-width: 100%;
}
.Connect_Header {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family: "Philosopher";
}

.Connect_Header .Connect_Header_Title {
  font-size: 2.5vw;
  color: rgb(199, 171, 104);
  font-weight: bold;
  padding-top: 2%;
  padding-bottom: 2%;
}

.Connect_Header .Connect_Header_SubTitle {
  font-size: 1.5vw;
  font-family: "Roboto";
  color: rgb(18, 71, 66);
  font-weight: bold;
}

@media (max-width: 767px), (min-height: 1000px) {
  .Connect_Header .Connect_Header_SubTitle {
    font-size: 2vw;
    font-family: "Roboto";
    color: rgb(18, 71, 66);
    font-weight: bold;
  }

  .Connect_Box {
    height: 80%;
    flex-direction: column;
  }
  .ConnectForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5%;
  }

  .ConnectForm input,
  .ConnectForm button {
    width: 100%;
    font-size: 2vw;
  }
  .ConnectIcons {
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
  }

  .ConnectIcons a,
  .ConnectIcons img {
    display: inline-block; /* Ajustado para inline-block */
    width: 24%;
    min-width: 24%;
    gap: 1%;
    margin: 0;
    padding: 0;
    text-decoration: none; /* Remove o sublinhado dos links */
  }

  .ConnectIcons a img {
    display: block;
    object-fit: contain;
    width: 100%; /* Ajustado para 100% para ocupar toda a largura do link */
    margin: 0;
    padding: 0;
    max-width: 100%;
    min-width: 100%;
  }
}
