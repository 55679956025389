.Treatments_Box {
  position: relative;
  width: 100%;
  flex-grow: 2;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  max-height: auto;
  padding-bottom: 10%;
}

.Treatments_Header {
  width: 80%;
  margin: 0 auto;
  aspect-ratio: 1/0.2;
  display: flex;
  align-items: center;
  font-family: "Philosopher";
  font-size: 2.5vw;
  color: #c7ab68;
}

.Treatments_Row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
  aspect-ratio: 1/0.2;
}
.Treatments_Label {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
}
.Treatments_Label label {
  width: 20%;
  font-size: 1.3vw;
  text-align: center;
  color: #124742;
  font-weight: 700;
  font-family: "Roboto";
}

@media (max-width: 767px), (min-height: 1000px) {
  .Treatments_Label label {
    font-size: 2vw;
  }
}
