.Home_Services {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  min-height: 30vw;
  flex-grow: 2;
}

.Home_Services .MainBox {
  display: flex;
  max-width: 80%;

  width: 80%;
  height: auto;

  aspect-ratio: 1/0.3;
}

@media (max-width: 767px), (min-height: 1000px) {
  .Home_Services {
    position: relative;
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .Home_Services .MainBox {
    flex-direction: column;
    justify-content: space-evenly;
    width: 40%;
    aspect-ratio: 1/5;
    aspect-ratio: none;
  }
}
