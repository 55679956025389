.Home_Box{
    position: relative;
    width: 100%;
    flex-grow: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-height: auto;
    
}



.Home_Box header{
    position: relative;
    height: fit-content;
    width: 100%;
    background:url('../../assets/Home/Header/gold_pattern.jpg');
    min-width: 100%;
    background-size:cover;
    aspect-ratio: 1/0.228645833;
}

.FirstLayer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: fit-content;
    height: 100%;
    background:url('../../assets/Home/Header/recepção.jpg') no-repeat;
    background-size:cover;
    aspect-ratio: 1/0.749367089;
}

.TextBox{
    margin: 0 auto;
    width: 50%;
    text-align: right;
}

.FirstLine,.SecondLine{
    display: block;
    font-family: 'Philosopher';
    color: #ffffff;
   
}

.FirstLine{
    font-weight: 400;
    font-size:3vw;
    height: fit-content;
}

.SecondLine{
    font-size: 1.5vw;
    height: fit-content;
}

.ThirdLine{
    display: block;
    font-family: 'CallingHeart';
    font-size: 5vw;
    height: fit-content;
    color: #c7ab68;
}

.SecondLayer{
    position: absolute;
    top: 0;
    background-color: transparent;
    z-index: 10;
    background-color: red;
    width: 100%;
    height: 100%;
}


.Header_LeftBox, .Header_RightBox{
    vertical-align: top;
    display: inline-block;
    height: 100%;
    max-height:100%;
    
}

.Header_LeftBox{
    position: absolute;
    left: 10%;
    aspect-ratio: 1/0.894160584;
}

.Header_RightBox{
    position: absolute;
    right: 18%;
    aspect-ratio: 1/1.12254902;
}

