.Service_Box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: fit-content;
  max-height: 100%;
  max-width: 25%;
  min-height: 100%;
  background-color: #124742;
  border: 0.1vw solid white;
  border-top: 1vw solid #124742;
  border-bottom: 1vw solid #124742;
}

.Service_Box:hover {
  transition: 0.5s ease;
  transform: translateY(-1vw);
}

.Service_Box .Content {
  flex-grow: 2;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Service_Box .Content .Title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Philosopher";
  font-size: 1.5vw;
  height: 20%;
}

.Service_Box .Content img {
  max-height: 100%;

  display: block;
  min-width: 100%;
}

.History_Gallery {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Gallery_Images {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.inner {
  display: inline-block;
  position: relative;

  height: 100%;
}

.Gallery_Images img {
  margin: 0 auto;
  height: 80%;
  max-height: 100%;
}

@media (max-width: 767px), (min-height: 1000px) {
  .Service_Box {
    height: 25%;
    min-height: 25%;
    width: 100%;
    max-width: 100%;
  }

  .Service_Box .Content .Title {
    font-size: 2.5vw;
  }
}
