.ProfileBox {
  padding-top: 2%;
  padding-bottom: 5%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 80%;
}

.Gallery_Images {
  display: inline-block;
  position: relative;
  width: 30%;
  max-width: 30%;
  flex-grow: 0;
  height: fit-content;
  overflow: hidden;
  border-radius: 0.3vw;
  border-bottom-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border: 0.5vw solid #124742;
  filter: drop-shadow(1vw 2.2vw 0.2vw rgba(18, 71, 66, 0.35));
  -webkit-filter: drop-shadow(1vw 2.2vw 0.2vw rgba(18, 71, 66, 0.35));
}

.inner {
  display: inline-block;
  position: relative;
  right: -50%;
  height: 100%;
}

.Gallery_Images img {
  min-width: 100%;
  width: 100%;
}

.ProfileBox_Content {
  width: 65%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: left;
}

.ProfileBox_Content_Name {
  font-size: 1.5vw;
  color: #124742;
  font-weight: 700;
  font-family: "Roboto";
}

.ProfileBox_Content_CRO {
  color: #c7ab68;
  font-family: "Roboto";
  font-size: 1.3vw;
}

.ProfileBox_Content_Title {
  font-size: 1.2vw;
  color: #124742;
  font-weight: 700;
  font-family: "Roboto";
}

.ProfileBox_Content_Text {
  width: 70%;
  font-size: 1.2vw;
}

@media (max-width: 767px), (min-height: 1000px) {
  .ProfileBox_Content_Name {
    font-size: 3vw;
  }
  .ProfileBox_Content_Title {
    font-size: 3vw;
  }
  .ProfileBox_Content_CRO {
    font-size: 2.8vw;
  }

  .ProfileBox_Content_Text {
    font-size: 2.5vw;
  }
}
