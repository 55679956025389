.Box_Elements {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  position: relative;
  width: 90%;
  height: 96%;
  margin: 0 auto;
}

.Blog_Box_Header {
  width: 90%;
  height: 30%;
  max-height: 30%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-transform: uppercase;
  font-size: 1.5vw;
  font-family: "Roboto";
  font-weight: bold;
  color: #124742;
}

.Blog_Box_Content {
  width: 80%;
  font-family: "Roboto";
  font-size: 1vw;
  font-weight: 300;
  color: #414042;
  height: 35%;
  max-height: 35%;
}

.Blog_Box_Footer {
  display: flex;
  align-items: center;
  width: 80%;
  height: 35%;
}

.Blog_Box_Footer .ButtonArea {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  height: 100%;
}

.Blog_Box_Footer .ButtonArea .Button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 350;
  font-family: "Dejavu Sans";
  font-size: 2vw;
  width: 20%;
  aspect-ratio: 1/1;
  background-color: #c7ab68;
  cursor: pointer;
}

.Blog_Box_Footer .ContentLink {
  cursor: pointer;
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #c7ab68;
  font-size: 1.5vw;
  white-space: nowrap;
}
